import request from '@/utils/request'

// pc端评估列表
export function listCaseResult(params) {
  return request({
    url: '/api/case-results',
    method: 'get',
    params,
  })
}

// pc端评估导出
export function exportCaseResult(params) {
  return request({
    url: `/api/case-results/export`,
    method: 'get',
    params
  })
}

// 查询测评记录
export function listEvaluation(params) {
  return request({
    url: '/api/evaluations',
    method: 'get',
    params,
  })
}